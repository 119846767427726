let REG = {
    email: {
        rule: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,9}$/,
        text: '邮箱格式不正确 正确格式: example@exunde.com'
    },
    phone: {
        rule: /^[1-9]\d{10}$/,
        text: '手机格式不正确 正确格式: 13888888888'
    },
    password: {
        rule: /[\w\-_]{6,}/,
        text: '密码长度最少6位字符'
    },
    float: {
        rule: /^[1-9]\d*$|0\.\d+$|[1-9]\d*\.\d+$/,
        text: '请输入非负数字'
    },
    int: {
        rule: /^[1-9]\d*$/,
        text: '请输入正整数'
    },
    int_0: {
        rule: /^([1-9]\d*|0)$/,
        text: '请输入非负整数'
    },
    require: {
        rule: /\S+/,
        text: '此项必填'
    }
}
class ValidateForm {
    constructor(opt) {
        this.form = $(opt.el)
        this.result = false,
        this.submit_ajax = opt.submit_ajax || false
        this.submit = opt.submit || null
        this.method = opt.method || this.form.attr('method') || 'get'
        this.url = opt.url || this.form.attr('action') || './'
        this.submit_btn = this.form.find('[type="submit"]')
        this.scroll_focus = opt.scroll_focus !== undefined ? opt.scroll_focus : true
    }

    init() {
        var that = this
        this.form.bind('submit', function(event) {
            if (that.submit_btn.hasClass('fetching')) {
                event.preventDefault()
                return
            }
            if (that.to_all()) {
                that.submit_btn
                    .addClass('fetching')
                    .prepend('<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>')

                if (that.submit_ajax) {
                    event.preventDefault()
                    that.go(event)
                    return
                }
            } else {
                event.preventDefault()
                return
            }
            
        })

        this.form.find('[data-validate]:not([data-validate=""])').each((i, el) => {
            if (
                $(el).is('[type="text"]') ||
                $(el).is('[type="password"]') ||
                $(el).is('[type="email"]') ||
                $(el).is('[type="number"]')
            ) {
                $(el).blur(function() {
                    that.to_input(el)
                })
            }

            if ($(el).is('select')) {
                $(el).change(function() {
                    that.to_select(el)
                })
            }

            if ($(el).is('textarea')) {
                $(el).blur(function() {
                    that.to_select(el)
                })
            }

            if ($(el).is('[type="checkbox"')) {
                $(el).change(function() {
                    that.to_checkbox(el)
                })
            }

            if ($(el).is('[type="radio"')) {
                $(el).change(function() {
                    that.to_radio(el)
                })
            }

        }) 
    }

    to_select(el) {
        var value = $(el).val()
        if ($(el).find('option:selected').text() == '请选择') value = ''
        this.validate_value(el, value)
    }

    to_input(el) {
        this.validate_value(el, $(el).val())
    }

    to_checkbox(el) {
        this.validate_value(el, $(el).is(':checked') ? 'check' : '')
    }

    to_textarea(el) {
        this.validate_value(el, $(el).val())
    }

    to_radio(el) {
        this.validate_value(el, this.form.find('[type="radio"][name="'+$(el).attr('name')+'"]:checked').val())
    }

    to_hidden(el) {
        this.validate_value(el, $(el).val())
    }

    to_all() {
        var that = this
        this.form.find('[data-validate]').each((i, el) => {
            if (
                $(el).is('[type="text"]') ||
                $(el).is('[type="password"]') ||
                $(el).is('[type="email"]') ||
                $(el).is('[type="number"]')
            ) {
                that.to_input(el)
            }

            if ($(el).is('select')) {
                
                that.to_select(el)
                
            }

            if ($(el).is('textarea')) {
                
                that.to_select(el)
                
            }

            if ($(el).is('[type="checkbox"')) {
                
                that.to_checkbox(el)
                
            }

            if ($(el).is('[type="radio"')) {
                
                that.to_radio(el)
                
            }

            if ($(el).is(':hidden')) {
                this.to_hidden(el)
            }
        })

        var $invalidateIpts = this.form.find('[data-invalidate]')
        if ($invalidateIpts.length) {
            if (this.scroll_focus) {
                $('html, body').animate({scrollTop: $invalidateIpts.eq(0).offset().top - 350});
            }
            return false
        } else {
            return true
        }
    }

    validate_value(el, value) {
        var types = $(el).data('validate').split(' ')
        var rs = true
        types.length > 0 && types.forEach((type, i) => {
            if (!type.trim().length) return
            if (!REG[type].rule.test(value)) {
                rs = false
            }
        })

        if (rs) {
            $(el).removeAttr('data-invalidate').removeClass('border-warning')
            if ($('div.validate_form_tip[data-validate-name="' + ($(el).attr('name') || $(el).attr('type')) +'"]').length > 0) {
                $('div.validate_form_tip[data-validate-name="' + ($(el).attr('name') || $(el).attr('type')) +'"]').remove()
            }
        } else {
            if (!$('div.validate_form_tip[data-validate-name="' + ($(el).attr('name') || $(el).attr('type')) +'"]').length) {
                this.validate_tip(el, value)
            }
            $(el).attr('data-invalidate', true).addClass('border-warning')
            
        }

        return rs
    }

    validate_tip(el, value) {
        var $text = $(el).data('validate-text') ? $(el).data('validate-text').split('|') : []
        var typs = $(el).data('validate').split(' ')
        typs.forEach(ty => {
            if (!ty.trim().length) return
            if(!REG[ty].rule.test(value)) $text.push(REG[ty].text)
        })
        var _text = ''
        $text.forEach(t => {
            _text += `<li>${t}</li>`
        })
        var $html =  $('<div class="validate_form_tip"></div>')
        $html.attr('data-validate-name', $(el).attr('name')).html(`<i class="arrow-up-shadow"></i><i class="arrow-up"></i><ul>${_text}</ul>`)
        
        var _el = $(el).is(':hidden') ? $(el).parent() : $(el)
        var _el_offset = _el.offset()
        $html.css({ top: _el_offset.top + _el.outerHeight(), left: _el_offset.left })
        // $html.css({ top: _el_offset.top, left: _el_offset.left + _el.outerWidth() + 10 })
        $('body').append($html)
    }

    go(event) {
        var that = this
        $.ajax({
            url: this.url,
            method: this.method,
            data: this.form.serialize()
        })
        .success(function(res) {
            that.submit_btn.removeClass('fetching').find('svg').remove()
            if (!res.status) {
                windowAlert('danger', res.message)
            }
            if (res.status) {
                windowAlert('success', res.message)
            }
            that.submit && that.submit(res)
        })
        .error(function(err) {
            windowAlert('danger', '服务器响应出了问题...请稍后再试')
        })
    }
}